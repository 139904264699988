import React, { useState } from "react";
import './nabvar.css'
import Logo from '../../assets/img/logo.png'
import Menu from '../../assets/img/menu.png'
import IconPhone from "../../assets/img/phone_ligh.png";
import IconMail from "../../assets/img/mail_ligh.png";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

const Nabvar = () => {
  const [modalIsMenu, setModalIsMenu] = useState(false)
  const navigate = useNavigate();
  const screen = window.matchMedia('(max-width: 820px)').matches

  return (
    <nav className="navbar">
      <div className="container">
      <div className="container-fluid">
        <Link class="navbar-brand" to={'home'}>
          <img src={Logo} alt="Soft Landing World" />
        </Link>

        {
          !screen ? (
            <ul class="nav">
          <li class="nav-item">
            <Link class="nav-link_a fontInter text-white fs_16" to={'que-es-soft-landing'}>
              ¿QUÉ ES EL SOFT LANDING?
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link_a fontInter text-white fs_16" to={'etapa'}>
              ETAPAS
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link_a fontInter text-white fs_16" to={'beneficios'}>
              BENEFICIOS
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link_a fontInter text-white fs_16" to={'rubros-de-servicios'}>
              RUBROS DE SERVICIOS
            </Link>
          </li>

          <li class="nav-item">
            <Link class="nav-link_a fontInter text-white fs_16" to={'paises-de-cobertura'}>
              PAÍSES DE COBERTURA
            </Link>
          </li>
        </ul>
          ) : (
            <>
            <div className="menu">
              <img src={Menu} onClick={() => {
                setModalIsMenu(!modalIsMenu);
              }} style={{cursor: 'pointer'}} alt="" />
            </div>
            <ReactModal
              isOpen={modalIsMenu}
              onRequestClose={() => {
                setModalIsMenu(false);
              }}
              style={{
                content: {
                  width: '300px',
                  right: '0',
                  left: 'auto',
                  margin: 'auto',
                  backgroundColor: '#000',
                  borderWidth: 0,
                  borderRadius: 0,
                  marginTop: '24px',
                  padding: '30px'
                },
              }}
            >
              <div className="mobile_menu">
                <div className="row border_B">
                  <li class="nav-item">
                    <Link class="nav-link_a fontInter text-white fs_16" onClick={() => setModalIsMenu(false)} to={'que-es-soft-landing'}>
                      ¿QUÉ ES EL SOFT LANDING?
                    </Link>
                  </li>
                </div>
                <div className="row border_B">
                    <h5 className="fontInter fs_16" >SLW</h5>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false); navigate('/beneficios')}}>Beneficios</p>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/etapa')}}>Etapas</p>
                </div>
                <div className="row border_B">
                    <h5 className="fontInter fs_16" >SERVICIOS & COBERTURA</h5>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/rubros-de-servicios')}}>Rubros de Servicios</p>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/paises-de-cobertura')}}>Países de Cobertura</p>
                </div>
                <div className="row border_B">
                    <h5 className="fontInter fs_16" >FORMULARIOS</h5>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/clientes')}}>Para Clientes</p>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/socios')}}>Para Profesionales</p>
                </div>
                <div className="row border_B">
                    <h5 className="fontInter fs_16" >SUSCRIPCIONES</h5>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/suscripciones', { state: { scroll: false } })}}>SL Tradicional</p>
                    <p className="fontinter fs_16" onClick={() => {setModalIsMenu(false);navigate('/suscripciones', { state: { scroll: true } })}} >SL Estratégico</p>
                </div>
                <div className="row border_B">
                  <h5 className="fontInter fs_16" >CONTACTENOS</h5>
                  <p className="fontinter fs_16" > 
                    <a href="mailto:admin@softlandingworld.com" style={{ textDecoration: 'none'}}> 
                      <img src={IconMail} width="20px" alt="" /> admin@softlandingworld.com 
                    </a>
                  </p>
                  <p className="fontinter fs_16"  > 
                    <a href="tel:+5491166804951" style={{textDecoration: 'none'}}> 
                      <img src={IconPhone} width="20px" alt="" /> +54 9 11 66804951
                    </a>
                  </p>
                </div>
              </div>
            </ReactModal>
          </>
          )
        }

        

      </div>
      </div>
    </nav>
  );
};

export default Nabvar;
