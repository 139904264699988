import React, { useState } from 'react'
import './mapa.css'
import MapaTop from '../../../assets/img/mapa top.png'
import MapaPin from '../../../assets/img/map-pin.png'
import ChevronRight from '../../../assets/img/chevron-left.png'
import { useNavigate } from 'react-router-dom'

const Mapa = () => {
  const navigate = useNavigate();
    const [heightImage, setHeightImage] = useState("900px")

    const screen = window.matchMedia('(max-width: 820px)').matches
    const mobile = window.matchMedia('(max-width: 540px)').matches
    
  return (
    <>
    <div className="container-banner mapa" >
        <div className={`show_image ${heightImage === '900px' ? "max" : "mini"}`} style={{height: heightImage}} >
            <img src={MapaTop} width="100%" className={heightImage === '900px' ? 'position_bottom' : 'position_center'}  />
        </div>
        
          <div className={`conteiner_top ${mobile && "mobil"}`}>
            <div className="conteiner_dual">
            {screen && (
              <div className="row">
                <p className={heightImage === '900px' ? "title_benner fontInter text-white fs_16" : "title_benner fontInter text-white fs_16 black"}>
                  La red de consultoras y profesionales Soft Landing World cuenta con presencia en los siguientes países:
                </p>
              </div>
            )}
              
            <div className="row">
              {
                !screen && (
                  <div className="col-4">
                  <div className="container_pri">
                  <p className={heightImage === '900px' ? "title_benner fontInter text-white fs_16" : "title_benner fontInter text-white fs_16 black"}>
                    La red de consultoras y profesionales Soft Landing World cuenta con presencia en los siguientes países:
                  </p>

                  <div className="button_ubicacion" onClick={() => {
                      if (heightImage === '900px') {
                          setHeightImage("95px")
                      }else {
                          setHeightImage("900px")
                      }
                      
                  }}>
                    <img src={heightImage === '900px' ? MapaPin : ChevronRight} className='image_button' width={heightImage === '95px' && '70px'} alt="" />
                    {
                      heightImage === '900px' && 
                      <h2 className="text_button fontInterBold text-white fs_24">
                        VER LOCALIZACIONES EN EL MAPA
                      </h2>
                    }
                    
                  </div>
                  </div>                 
                </div>
                )
              }
                
                
                {
                  heightImage === '900px' && (
                    <>
                      <div className={!screen ? "col-2" : mobile ? "col-3" : "col-4"}>
                  <div className="tag">
                    <div className="item_tag_title">
                      <span className='fontInter text-white fs_24' >EUROPA</span>
                    </div>
                    <div className="item_tag">
                      <span>ESPAÑA</span>
                    </div>
                    <div className="item_tag">
                      <span>GRAN BRETAÑA</span>
                    </div>
                    <div className="item_tag">
                      <span>PORTUGAL</span>
                    </div>
                    <div className="item_tag">
                      <span>ITALIA</span>
                    </div>
                    <div className="item_tag">
                      <span>BULGARIA</span>
                    </div>
                  </div>
                </div>
                <div className={mobile ? "col-3" : "col-2"}>
                  <div className="tag">
                    <div className="item_tag_title">
                      <span className='fontInter text-white fs_24' >NORTE AMÉRICA</span>
                    </div>
                    <div className="item_tag">
                      <span>EE UU</span>
                    </div>
                    <div className="item_tag">
                      <span>MÉXICO</span>
                    </div>
                    <div className="item_tag">
                      <span>CANADÁ</span>
                    </div>
                  </div>
                </div>
                <div className={mobile ? "col-3" : "col-2"}>
                  <div className="tag">
                    <div className="item_tag_title">
                      <span className='fontInter text-white fs_24' >CENTRO AMÉRICA</span>
                    </div>
                    <div className="item_tag">
                      <span>COSTA RICA</span>
                    </div>
                    <div className="item_tag">
                      <span>GUATEMALA</span>
                    </div>
                    <div className="item_tag">
                      <span>HONDURAS</span>
                    </div>
                    <div className="item_tag">
                      <span>PANAMÁ</span>
                    </div>
                  </div>
                </div>
                <div className={!screen ? "col-2" : mobile ? "col-3" : "col-4"}>
                  <div className="tag">
                    <div className="item_tag_title">
                      <span className='fontInter text-white fs_24' >SUD AMÉRICA</span>
                    </div>
                    <div className="item_tag">
                      <span>ARGENTINA</span>
                    </div>
                    <div className="item_tag">
                      <span>BOLIVIA</span>
                    </div>
                    <div className="item_tag">
                      <span>BRASIL</span>
                    </div>
                    <div className="item_tag">
                      <span>CHILE</span>
                    </div>
                    <div className="item_tag">
                      <span>COLOMBIA</span>
                    </div>
                    <div className="item_tag">
                      <span>ECUADOR</span>
                    </div>
                    <div className="item_tag">
                      <span>PARAGUAY</span>
                    </div>
                    <div className="item_tag">
                      <span>PERÚ</span>
                    </div>
                    <div className="item_tag">
                      <span>URUGUAY</span>
                    </div>
                  </div>
                </div>
                    </>
                  )
                }

              </div>
            </div>

            {screen && (
                <div className={heightImage === '900px' ? "button_ubicacion" : "button_ubicacion chevron"} onClick={() => {
                  if (heightImage === '900px') {
                      setHeightImage("95px")
                  }else {
                      setHeightImage("900px")
                  }
                  
                  }}>
                    <img src={heightImage === '900px' ? MapaPin : ChevronRight} className='image_button' width={heightImage === '95px' && '70px'} alt="" />
                    {
                      heightImage === '900px' && 
                      <h2 className="text_button fontInterBold text-white fs_24">
                        VER LOCALIZACIONES EN EL MAPA
                      </h2>
                    }
                    
                </div>
              )}
                  
            <div class="d-grid gap-2 col-8 mx-auto">
              <button onClick={() => navigate('/clientes')} class="btn btn-primary btn-new" type="button">
                <span className="text_button fontInterBold text-white fs_24">COTIZAR</span>
              </button>
            </div>

            </div>

    </div>
    </>
  )
}

export default Mapa