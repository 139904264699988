import React from 'react'
import './etapa.css'

const Etapa = ({image, title, subTitle, styleImg, children, isChildren = true, classe, handleEtapa}) => {
  const screen = window.matchMedia('(max-width: 820px)').matches

  return (
    <div className={"contenido_card " + classe + `${isChildren && " active"}`} onClick={() => handleEtapa(classe)}>
          <div className="body_card">
            <img src={image} className='card_image' style={styleImg} />
            <div className="header_text">
              <span className="fontInter text-white fs_20 ">FASE</span><br />
              <span className="subTitlecenter fontInterBold fs_32">{title}</span><br />
              <span className="subTitleBottom fontInterBold fs_24">{subTitle}</span>
            </div>
            <div className="footer_text fontInter fs_20">
                {(!screen || isChildren) && children}
            </div>
          </div>
        </div>
  )
}

export default Etapa