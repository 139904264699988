import React from "react";
import "./banner.css";

const Banner = ({image, height, classProps, children}) => {
  return (
    <div className={classProps + " banner"} style={{height: height}}>
      <div className={classProps + " img-banner"}>
        <img src={image} width="100%"/>
      </div>
      {children}
    </div>
  );
};

export default Banner;
