import React from 'react'
import './softLanding.css'
import { Link } from 'react-router-dom'

const SoftLanding = () => {
  const mobile = window.matchMedia('(max-width: 540px)').matches

  return (
    <div className="container soft_landing pb-3">

      <div className={`row ${mobile && "mobil"}`}>
        <div className={`col-3 ${mobile && "col-12"}`}>
          <h1 className='title fontInter text-black fs_24' >¿QUÉ ES SOFT LANDING?</h1>
        </div>
        <div className={`col-9 ${mobile && "col-12"}`}> 
          <p className='subTitle fontInter fs_16'>Es una expansión segura con un “aterrizaje suave” que realiza una organización <br />
           en otro país para colocar de forma legal sus productos y/o servicios. </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={`col-3 ${mobile && "col-12"}`}>
          <h2 className="fontinter fs_20" >¿Cómo surge el Soft Landing?</h2>
        </div>
        <div className={`col-8 ${mobile && "col-12"}`}> 
          <p>
            Desde hace muchos años el ser humano busca <strong>nuevos horizontes</strong> para expandir su radio de acción.
          </p>
          <p>
           Desde los fenicios hasta nuestros días la comercialización internacional tuvo distintas etapas, pero nunca se desarrolló con la magnitud de <strong>velocidad del presente.</strong>
          </p>
          <p>
            La revolución de las TICs, particularmente en lo referido a las comunicaciones, generó un <strong>acercamiento cada vez mayor entre el comprador y el vendedor localizados en distintos países.</strong>
          </p>
          <p>
            Ahora bien, las empresas tienen un expertise inherente a su actividad que saben realizar y se especializan en ello (ejemplo, fabricación de productos, desarrollo de software, etc.). Por lo tanto, siempre fue necesario <strong>contar con diversas gestiones propias de conocimientos ajenos al objeto principal de las compañías exportadoras.</strong>
          </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={`col-3 ${mobile && "col-12"}`}>
          <h2 className="fontinter fs_20" >¿A qué denominamos Soft Landing?</h2>
        </div>
        <div className={`col-8 ${mobile && "col-12"}`}> 
          <p>
            Es un conjunto de servicios, prácticas y gestiones que se desarrollan cuando una empresa quiere internacionalizar sus productos o servicios.
          </p>
          <p>
            Es una denominación de origen anglosajón que refiere al “aterrizaje suave, blando o flexible” que realiza una organización en otro país, para colocar el producido de su actividad.
          </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={`col-3 ${mobile && "col-12"}`}>
          <h2 className="fontinter fs_20" >¿Qué rubros de servicios comprende?</h2>
        </div>
        <div className={`col-8 ${mobile && "col-12"}`}> 
          <p>
            Si bien no existe un consenso para definir con exactitud el límite de todos los rubros de servicios que integran el softlanding, nuestra comunidad pone a disposición los siguientes:
          </p>
          <p>
            <a href="#"> 1. Legal / Notarial </a><br />
            <a href="#"> 2. Estudio de mercado </a><br />
            <a href="#"> 3. Contable - Asesoría financiera </a><br />
            <a href="#"> 4. Representaciones comerciales / ventas </a><br />
            <a href="#"> 5. Comex / Despachante de aduana </a><br />
            <a href="#"> 6. Logística internacional </a><br />
            <a href="#"> 7. Gestoría / Tramites varios </a><br />
            <a href="#"> 8. Real estate / inmobiliaria </a><br />
            <a href="#"> 9. Seguros </a><br />
            <a href="#"> 10. Recursos Humanos </a><br />
            <a href="#"> 11. Marketing, Planificación, Diseño y Comunicación </a><br />
            <a href="#"> 12. Coaching </a><br />
            <a href="#"> 13. Compliance Risk </a><br />
            <a href="#"> 14. Sistemas de Gestión y de IT </a><br />
            <a href="#"> 15. Consultoría en idiomas y Traductorado Público </a><br />
            <a href="#"> 16. Cobranzas corporativas </a><br />
          </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={`col-3 ${mobile && "col-12"}`}>
          <h2 className="fontinter fs_20" >¿Quiénes brindan servicio de Soft Landing?</h2>
        </div>
        <div className={`col-8 ${mobile && "col-12"}`}> 
          <p>
            Los profesionales y/o consultoras que brindan el servicio de softlanding son aquellas que cuentan con alguna disciplina que pueda aportarle valor al proyecto de una empresa a la hora de internacionalizar su producto o servicio.
          </p>
          <p>
            Algunas empresas cuentan con Departamento de Comercio Exterior, pero siempre van a necesitar de algún servicio extra para poder completar un buen softlanding.
          </p>
        </div>
      </div>

    <div className="d-flex justify-content-end mb-5">
      <Link className='btn_conocer fontInterBold fs_24' to={'/home'} >
        CONOCER SLW
      </Link>
    </div>
      

    </div>
  )
}

export default SoftLanding