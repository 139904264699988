import React, { useEffect, useState } from 'react'
import './terminoCondiciones.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const TerminoCondiciones = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const mobile = window.matchMedia('(max-width: 540px)').matches

    const [pageNavegate, setPageNavegate] = useState('')
    const [persist, setPersist] = useState()

    useEffect(() => {
      if ( location?.state) {
        setPageNavegate(location.state.page)
        setPersist(location.state.persist)
      }
      
  }, [location.state])

  return (
    <div className={`container soft_landing pb-3 ${mobile && "movile"}`}>

      <div className={`row ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h1 className='title' >TÉRMINOS Y CONDICIONES</h1>
        </div>
        <div className={!mobile ? "col-9" : "col-12"}> 
          <p className='subTitle fontInter fs_16'>¡Gracias por elegir la Red Soft Landing World! </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >1. General</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            Al registrarte y/o utilizar cualquiera de los servicios de la Red Soft Landing World (en
            adelante SLW), incluyendo los que brindan nuestros Asociados, todas las
            características y funcionalidades asociadas, sitios web e interfaces de usuario, así como
            todo el contenido y aplicaciones de software asociadas con nuestros servicios (en
            adelante el “Servicio”), estás aceptando los Términos y Condiciones.
            Al aceptar estos Términos y Condiciones declaras que la información de registro, o vía
            email o cualquier otra modalidad que envíes es verdadera, exacta y completa.
            Al utilizar cualquier servicio actual o futuro de SLW estarás sujeto a los Términos y
            Condiciones vigentes aplicables que se encontrarán siempre disponibles en la página
            www.softlandingworld.com
          <br />
            En caso de infringir estos Términos y Condiciones, nos reservamos el derecho a dar de
            baja el servicio a nuestra entera discreción, así como iniciar las acciones legales que
            fueren oportunas.
          </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >2. Propiedad</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            La Red Soft Landing World, SLW, el logo, marca, diseño, pagina web, así como toda
            otra identificación referida a la presente plataforma es propiedad SOFT LANDING
            WORLD SL, NIF B16866915, Domicilio en domicilio en C/ Sanguino 37 9 D - 38390 -
            Santa Úrsula - España.
          <br />
            Todos los derechos de autor, marcas registradas o no registradas, materiales o
            contenidos vinculados a la propiedad intelectual de SLW o de sus Miembros Asociados
            permanecerán, en todo momento, como derechos adquiridos, y así es aceptado por
            todos los Miembros en el momento de utilizar la Red SLW, así como cualquier Usuario
            y/o Consultante.
          <br />
            No se permite a clientes y usuarios, utilizar este material o contenido salvo
            autorización expresa y explícita del propietario. Además de ello, no estarán
            especialmente autorizados a copiar, reproducir, distribuir, explotar comercialmente
            tales materiales o contenidos, ni ayudará / facilitará a terceros en acciones como las
            mencionadas anteriormente.
          <br />
            La única excepción a la prohibición anterior, es toda referencia que pueden hacer los
            Miembros, con membresía vigente, del logo y marca en sus documentos de trabajo y/o
            redes sociales, con la mención “Miembro de la Red SLW”, acompañado del logo.
          </p>
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >3. Condiciones de Contratación de las Membresias o Suscripciones.</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            a. Contrato
            Al contratar una Membresía aceptas iniciar un contrato vinculante por el uso
            de la Red SLW. La adquisición de un plan se concreta una vez efectuado y
            verificado el pago correspondiente al primer período de uso.
          </p>
          <p>
            b. Período de Contratación
            El período de contratación será anual. La renovación será automática por un
            período idéntico al contratado, salvo solicitud de baja con un mínimo de
            notificación previa de un mes. Así tu membresía se renovará
            automáticamente. En tal caso, el monto se cobrará en un plazo no mayor a los
            10 días del vencimiento del período vigente.
          </p>
          <p>
            c. Precio
            Cada uno de los planes tiene sus características y restricciones de uso o
            prestación de servicio, y conforme a ello un precio determinado. Estos precios
            podrán estar sujetos a ajustes o variaciones. 
          </p>
          <p>
            d. Método de pago
            Podrás adquirir cualquier Plan utilizando como medio de Pago las plataformas
            de pago que se encuentren vigentes (ej. Paypal, Payoneer, Stripe, Mercado
            Pago, transferencia bancaria, etc.), quienes garantizarán la seguridad de las
            operaciones. <br />
            Ni SLW, ni sus titulares, ni sus administradores, así como ningún personal,
            recibe pagos en forma directa, y tampoco accede o almacena datos asociados
            a la tarjeta de crédito o similar del Cliente, por lo que no tiene responsabilidad
            alguna en la transacción específica de pago, constituyéndonos en meros
            destinatarios de dichos pagos.
          </p>
          <p>
            e. Revocación del Servicio
            Podrás cancelar la renovación automática en cualquier momento desde el
            módulo de gestión asociado a tu negocio.
            Dado que los servicios se pagan en forma previa al uso, no tendrás derecho a
            reclamo alguno sobre los pagos ya efectuados, y en ningún caso realizaremos
            una devolución parcial o total del mismo.
          </p>
          <p>
            f. Planes con Promoción
            SLW se reserva el derecho a la realización de acciones de promoción y
            otorgamiento de cupones de descuento o meses gratis a nuestro exclusivo
            criterio. En tal sentido, SLW podrá realizar dichas promociones por sector de
            actividad y/o el período de tiempo que determine, a su voluntad.
          </p>

        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >4. Responsabilidad</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            a. Registración <br />
            Para poder utilizar la red SLW, debes tener una cuenta de Miembro válida. El
            titular de la Membresía es el único responsable de la confidencialidad de la
            contraseña. 
            Toda la actividad originada desde su cuenta y / o contraseña es su
            responsabilidad. Le recomendamos que mantenga tu contraseña e información
            de la cuenta estrictamente confidenciales y no la comparta con nadie. En caso
            de uso no autorizado de la contraseña y /o cuenta, debe notificarnos
            inmediatamente por escrito enviando un correo electrónico a nuestra Área de
            Atención al Cliente.
            Ud. entiende y acepta expresamente que el uso de SLW, nuestros servicios y
            productos, se realiza a su propio riesgo y los servicios y productos se
            proporcionan &quot;tal cual&quot; y &quot;como están disponibles&quot;. 
            Reconoce y acepta además que no somos responsables de la disponibilidad 
            y / o funcionalidad de cualquier servicio externo y / o material que puedas acceder 
            a través del sitio de agendame.
          </p>
          <p>
            b. Uso Aceptable <br />
            Al aceptar estos Términos y Condiciones, acepta no realizar ninguna actividad
            que vaya en contra, este prohibida o viole los términos de servicio y
            entendiendo que es sujeto de medidas disciplinarias y/o acciones legales:
          </p>
          <p>
            i. Identidad <br />
            Ud. es totalmente responsable por la veracidad de los datos de
            registración, facturación y pago ingresados, y no se hará pasar por
            ninguna persona física o jurídica, ni tergiversarás tu afiliación con una
            persona física o jurídica.
          </p>
          <p>
            ii. Contenido <br />
            No publicarás ni distribuirás a través de SLW ningún material que sea
            de naturaleza difamatoria, amenazadora, obscena, dañina,
            pornográfica o ilegal.  <br />
            Los materiales que de alguna manera violen o infrinjan, de cualquier
            manera, nuestros derechos o los derechos de otros (incluidos, entre
            otros, los derechos de propiedad intelectual, los derechos de
            confidencialidad y los derechos de privacidad) están totalmente
            prohibidos, así como las actividades que puedan causar angustia o
            inconvenientes para nosotros u otros.  <br />
            Está prohibido expresar opiniones políticas, religiosas, vulgares,
            groseras, sexistas, racistas u ofensivas. 
            <br />
            SLW se reserva el derecho de eliminar cualquier expresión que escribas
            en contra de lo establecido aquí. Asimismo, de continuar su actitud
            podríamos llegar a dar de baja la suscripción o bien hacer las denuncias
            correspondientes ante las autoridades, de ser necesario.
          </p>
          <p>
            iii. Propiedad del contenido <br />
            No publicará ni pondrá a disposición de ninguna forma en la plataforma
            SLW ningún material que no sea de su propiedad o sin el
            consentimiento expreso del propietario legítimo del material en
            cuestión. <br />
            Cuando publiques o evies a SLW para su publicación un articulo o
            contenido de tu autoría estás cediendo a SLW la distribución y/o uso
            sin restricción alguna de dicho material
          </p>
          <p>
            iv. SPAM <br />
            No promocionará su actividad enviando correos electrónicos masivos o
            mensajes por cualquier medio, a cuentas o personas.
          </p>
          <p>
            v. Vínculo entre Asociados y/o Terceros <br />
            SLW es una plataforma para que los Miembros se vinculen entre sí y
            puedan otorgar y recibir un servicio, respectivamente. Asimismo,
            podrán brindar servicios a Usuarios de la plataforma que no sean
            Miembros y soliciten sus servicios. <br />
            No respaldamos, ni garantizamos, ni somos responsables de ningún
            contenido, publicidad, producto o servicio que se preste utilizando SLW,
            o gracias a nuestra Red. <br />
            Cualquier transacción entre los Miembros entre si y/o los Usuarios
            finales, incluido el pago y la entrega o recepción de productos, servicios
            y otros términos, condiciones, garantías o representaciones asociadas
            con dichas transacciones, o la calidad, cantidad, trato u otro de los
            productos y/o servicios ofrecidos, se realiza entre las partes
            mencionadas, sin intervención de SLW. <br />
            Por lo tanto, SLW no somos responsables de ninguna pérdida, daño y/o
            perjuicio de ningún tipo, producto de dicha relación. Los titulares y/o
            administradores y/o empleados de la Red SLW estarán exentos de toda
            responsabilidad civil y/o comercial y/o penal. <br />
            Si alguna de las leyes, decretos y/o cualquier normativa de algunos de
            los países donde se encuentran radicados los Miembros y/o cualquier
            jurisdicción que se considere con derecho a litigar, estableciere la
            responsabilidad en cabeza de los titulares y/o administradores de la
            Red SLW, dicha solidaridad quedará renunciada expresamente con la
            aceptación de la participación como Miembro o bien con la contratación
            que un Usuario externo realice de los servicios que se proveen gracias
            a SLW. Es decir que, la contratación de una Membresía, así como la
            utilización de la Red SLW implicará la renuncia al derecho que pudiera
            tener de responsabilizar SLW y a los titulares y/o administradores de la
            Red SLW, por cualquier incumplimiento y/o daño y/o perjuicio que un
            Miembre o algún usuario ajeno a SLW, pudiera causar.
          </p>
          <p>
            vi. Construcción de Comunidad <br />
            Teniendo en cuenta el espíritu comunitario y de beneficio para el
            conjunto, los Miembros aceptan la pertenencia a la red como un cuerpo
            que, respetando la individualidad, contiene las expectativas de
            internacionalización de los mismos Asociados. Por lo tanto, no están
            autorizados los Asociados o Miembros a realizar sociedades o redes o
            comunidades entre si, sin participación de SLW. Todos los lazos
            asociativos que se procuren para para ampliar y/u oficializar una red
            con nombre o métodos o acuerdos económicos, deberán ser
            informados y autorizados por SLW. Todo ello bajo pena de expulsión y
            pago de un valor del doble de la Membresía mas onerosa que existiere.
          </p>
          
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >5. Legislación aplicable, jurisdicción competente y notificaciones</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            Las presentes condiciones se rigen y se interpretan de acuerdo con las Leyes de
            Argentina. Para cualquier reclamación serán competentes los juzgados y
            tribunales con ubicación en la Ciudad Autónoma de Buenos Aires. Todas las
            notificaciones, requerimientos, peticiones y otras comunicaciones que el
            cliente/usuario desee efectuar deberán realizarse por escrito y se entenderá que
            han sido correctamente realizadas cuando hayan sido recibidas en la dirección:
            Adolfo Bellocq n° 4040, Vicente Lopez, Buenos Aires, Argentina.
          </p>
          
        </div>
      </div>

      <div className={`row mb-5 ${mobile && "mobil"}`}>
        <div className={!mobile ? "col-3" : "col-12"}>
          <h2 className="fontinter fs_20" >6. Descargo de responsabilidad</h2>
        </div>
        <div className={!mobile ? "col-8" : "col-12"}> 
          <p>
            El sólo hecho de haberte registrado y/o usar la Red SLW, da cuenta de que has
            leído y aceptado estos terminos y condiciones de uso.
          </p>
          
        </div>
      </div>

    <div className="d-flex justify-content-end mb-5">
      <a onClick={() => {
        pageNavegate && navigate(pageNavegate, { state: { acept: true, persist: persist } });
      }} className='btn_conocer fontInterBold fs_24'>
        ACEPTAR TÉRMINOS Y CONDICIONES
      </a>
    </div>
      

    </div>
  )
}

export default TerminoCondiciones