import React from 'react'
import './button.css'

const Button = ({title, subTitle}) => {
  return (
    <button class="btn btn-primary btn-new-circle" type="button">
        <div className="cont_button">
            <span className="b_title fs_48" dangerouslySetInnerHTML={{ __html: title}} />
            <span className="b_subTitle fs_14" dangerouslySetInnerHTML={{ __html: subTitle}} />
        </div>
    </button>
  )
}

export default Button