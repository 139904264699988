import React from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Maintenance from '../pages/maintenance/Maintenance'
import Home from '../pages/home/Home'
import Layout from '../layout/Layout'
import SoftLanding from '../pages/softLanding/SoftLanding'
import Etapa from '../pages/etapa/Etapa'
import Paises from '../pages/paises/Paises'
import { Beneficios } from '../pages/beneficios/Beneficios'
import Suscripciones from '../pages/suscripciones/Suscripciones'
import Socios from '../pages/socios/Socios'
import Clientes from '../pages/clientes/Clientes'
import TerminoCondiciones from '../pages/terminoCondiciones/TerminoCondiciones'

const AppRouter = () => {
  return (
    <Router>
      <Layout>
        <Routes>
            <Route path='/home' element={<Home />} />
            <Route path='/que-es-soft-landing' element={<SoftLanding />} />
            <Route path='/etapa' element={<Etapa />} />
            <Route path='/rubros-de-servicios' element={<Etapa />} />
            <Route path='/paises-de-cobertura' element={<Paises />} />
            <Route path='/beneficios' element={<Beneficios />} />
            <Route path='/suscripciones' element={<Suscripciones />} />
            <Route path='/socios' element={<Socios />} />
            <Route path='/clientes' element={<Clientes />} />
            <Route path='/terminos-y-condiciones' element={<TerminoCondiciones />} />

            <Route path='/maintenance' element={<Maintenance />} />
            <Route path='/' element={<Navigate to="home" />} />
            <Route path='*' element={<Navigate to="home" />} />
        </Routes>
      </Layout>
    </Router>
  )
}

export default AppRouter