import React, { useState } from 'react'
import './beneficios.css'
import { useNavigate } from 'react-router-dom'

const parrafo1 =  `<li><p> Ampliar sus propuestas de valor a sus clientes o mercado meta, ofreciendo todos los servicios de SLW. </p></li>
    <li><p>Recibir prospectos o consultas de empresas y de otros Asociados. </p></li>
    <li><p> Capacitar y ser capacitadores (SLW LEARNING). </p></li>
    <li><p>Usar la Plataforma Web www.softlandingworld.com </p></li>
    <li><p>Asistir a las Reuniones de Vinculación semanal </p></li>
    <li><p>Participar de los Programas Especiales (Business Point, Base de datos de Oportunidad de Negocios, etc)</p></li>
    <li><p>Asistir a los Encuentros Internacionales de Soft Landing </p></li>
    <li><p>Colaborar en algunas de la Comisiones de Trabajo</p></li>
    `
const parrafo2 =`<li><p>Solicitar cotización de los servicios de SLW</p></li>
    <li><p>Búsqueda de clientes y/o partners estratégicos</p ></li>
    <li><p>Capacitación a sus funcionarios</p></li>
    <li><p>Participación en Congresos, rondas de Negocios, Networking, etc</p></li>
`
const title1 = 'PROFESIONALES ASOCIADOS'
const title2 = 'EMPRESAS'


const Beneficios = () => {
    const navigate = useNavigate();
    const [select, setSelect] = useState(false)
    const [texto, setTexto] = useState({
        title: title1,
        title2: title2,
        parrafo: parrafo1
    })

    const screen = window.matchMedia('(max-width: 820px)').matches

    const changetext = () => {
        if (!select) {
            setTexto({
                title: title2,
                title2: title1,
                parrafo: parrafo2
            })
        }else {
            setTexto({
                title: title1,
                title2: title2,
                parrafo: parrafo1
            })
        }
        setSelect(!select)
    }


  return (
    <div className={`container-banner beneficios ${screen && "mobile"}`}>
      <div className="container container_banner">
            <p className="title_socios fontInter text-white fs_16 ">
                Esta plataforma está constituida por diversas Consultoras y/o Profesionales independientes, donde el usuario/empresario podrá escoger entre más de 18 países y los rubros de servicios que necesita.
            </p>
            <div className="card_bene">
                <div className="card_primary">
                    <div className="sec_title">
                        <span className="fontInter text-white fs_24">Beneficios {texto.title === 'EMPRESAS' ? 'para las' : 'de los'}</span><br />
                        <span className="fontInterBold text-white fs_32">{texto.title}</span>
                    </div>
                    <div className="parrafo">
                    <ul dangerouslySetInnerHTML={{__html: texto.parrafo}} />
                    </div>
                    
                </div>
                <div className="card_secondary">
                    <div className="sec_title" onClick={changetext} >
                        <span className="fontInter text-white fs_24">Beneficios {texto.title2 === 'EMPRESAS' ? 'para las' : 'de los'}</span><br />
                        <span className="fontInterBold text-white fs_32">{texto.title2}</span>
                    </div>
                </div>
            </div>
      </div>

      <div class="d-grid gap-2 col-8 mx-auto">
        <button onClick={() => navigate(texto.title !== 'EMPRESAS' ? '/suscripciones' : '/clientes')} class="btn btn-primary btn-new" type="button">
          <span className="text_button">{!select ? 'QUIERO ASOCIARME' : 'COTIZAR'}</span>
        </button>
      </div>
    </div>
  )
}

export default Beneficios