import React, { useEffect, useRef, useState } from 'react'
import ChevronLeft from '../../assets/img/chevron-left.png'
import ChevronRight from '../../assets/img/chevron-right.png'
import './socios.css'
import { useLocation, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactModal from 'react-modal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { enviarCorreo } from '../../services/correo';

const opciones = ['Argentina', 'Brasil', 'Colombia', 'Paraguay', 'Uruguay', 'Bolivia', 'Chile', 'Ecuador', 'Perú', 
'Costa Rica', 'Honduras', 'Guatemala', 'Panamá', 'Canadá', 'Máxico', 'Estados Unidos', 
'Bulgaria', 'Italia', 'Gran Bretaña', 'España', 'Portugal'];

const rubros = ['Legal / Notarias', 'Estudio de Mercado', 'Representaciones Comerciales - ventas', 'Gestoría', 'Seguros', 
'Marketing - Planificación - Diseño y Comunicación', 'Compliance Risk', 'Contable - Asesoría Financiera', 
'Comex - Despachante de Aduana', 'Logística Internacional', 'Real Estate / Inmobiliaria', 'Recursos Humanos', 'Coaching', 'Sistema de Gestión y de IT', 
'Consultoría en Idiomas - Traductorado Público', 'Cobranzas corporativas']

const Socios = () => {
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const mobile = window.matchMedia('(max-width: 540px)').matches

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesEmpresa, setSelectedFilesEmpresa] = useState([]);

  const [selectedFilesValidate, setSelectedFilesValidate] = useState(false)
  const [selectedFilesEmpresaValidate, setSelectedFilesEmpresaValidate] = useState(false)

  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);
  const [opcionesSeleccionadasRubros, setOpcionesSeleccionadasRubros] = useState([]);

  const [opcionEstrategica, setOpcionEstrategica] = useState(false);
  const [opcionTradicional, setOpcionTradicional] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const [isAceptar, setIsAceptar] = useState(false)
  const [isChecked, setIsChecked] = useState(false);

  const [step, setStep] = useState(1)

  const [editorHtml, setEditorHtml] = useState('');

  const [formDataStep1, setFormDataStep1] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    idPersonal: ''
  })

  const [formValidStep1, setFormValidStep1] = useState({
    nombre: false,
    apellido: false,
    correo: false,
    telefono: false,
    idPersonal: false
  });

  const [formDataStep3, setFormDataStep3] = useState({
    empNombre: '',
    empTelefono: '',
    empWeb: '',
    empIdTributo: '',

    empCalle: '',
    empPiso: '',
    empLocalidad: '',
    empPais: '',
    empNumero: '',
    empDepartamento: '',
    empEstado: '',
    empHAtencion: '',
  })

  const [formValidStep3, setFormValidStep3] = useState({
    empNombre: false,
    empIdTributo: false,

    empCalle: false,
    empLocalidad: false,
    empPais: false,
    empNumero: false,
    empEstado: false,
    empHAtencion: false,
  });

    useEffect(() => {
        if ( location?.state) {
          setIsAceptar(location.state.acept)

          setFormDataStep1( location.state.persist.formDataStep1 )
          setFormDataStep3( location.state.persist.formDataStep3 )
          setEditorHtml( location.state.persist.editorHtml )
          setOpcionEstrategica( location.state.persist.opcionEstrategica )
          setOpcionTradicional( location.state.persist.opcionTradicional )
          setOpcionesSeleccionadas( location.state.persist.opcionesSeleccionadas )
          setOpcionesSeleccionadasRubros( location.state.persist.opcionesSeleccionadasRubros )
          setSelectedFiles( location.state.persist.selectedFiles )
          setSelectedFilesEmpresa( location.state.persist.selectedFilesEmpresa )

          setStep(7)
        }
        
    }, [location.state])

    const handleChangeEditor = (html) => {
      setEditorHtml(html);
    };

  const handleNext = () => {

    var allFieldsValid = true
    
    if (step === 1) {

      Object.entries(formDataStep1).map((data) => {
        if (data[1] === '') {
          allFieldsValid = false
          setFormValidStep1((prevFormValidStep) => ({
            ...prevFormValidStep,
            [data[0]]: true,
          }))
        }
        return null;
      })

      if (selectedFiles.length === 0) {
        allFieldsValid = false
        setSelectedFilesValidate(true)
      }
  
    } else if (step === 2) {
      
      Object.entries(formDataStep3).map(data => {
        if (data[1] === '' && formValidStep3.hasOwnProperty(data[0])) {
          allFieldsValid = false
          setFormValidStep3((prevFormValidStep) => ({
            ...prevFormValidStep,
              [data[0]]: true,
            })
          )
        }

        if (selectedFilesEmpresa.length === 0) {
          allFieldsValid = false
          setSelectedFilesEmpresaValidate(true)
        }

        return null;
      })

    }else if (step === 3 && opcionesSeleccionadas.length === 0){
      allFieldsValid = false
    }else if (step === 4 && opcionesSeleccionadasRubros.length === 0){
      allFieldsValid = false
    }else if (step === 5 && (!opcionEstrategica && !opcionTradicional)){
      allFieldsValid = false 
    }
    if (allFieldsValid) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonClick2 = () => {
    fileInputRef2.current.click();
  };

  const handleCheckboxChangeReCaptcha = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckboxChange = (opcion) => {
    if (opcionesSeleccionadas.includes(opcion)) {
      setOpcionesSeleccionadas(opcionesSeleccionadas.filter((item) => item !== opcion));
    } else {
      setOpcionesSeleccionadas([...opcionesSeleccionadas, opcion]);
    }
  };

  const handleCheckboxChangeRubros = (opcion) => {
    if (opcionesSeleccionadasRubros.includes(opcion)) {
      setOpcionesSeleccionadasRubros(opcionesSeleccionadasRubros.filter((item) => item !== opcion));
    } else {
      setOpcionesSeleccionadasRubros([...opcionesSeleccionadasRubros, opcion]);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {

        const base64 = reader.result.split(',')[1];
        setSelectedFiles([...selectedFiles, {name: file.name, base64}]);
      };

      reader.readAsDataURL(file);
    }
    setSelectedFilesValidate(false);
  };

  const handleFileChangeEmpresa = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {

        const base64 = reader.result.split(',')[1];
        setSelectedFilesEmpresa( {name: file.name, base64});
      };

      reader.readAsDataURL(file);
    }

    setSelectedFilesEmpresaValidate(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (step === 1){
      setFormDataStep1({
        ...formDataStep1,
        [name]: value,
      });
      formValidStep1[name] && setFormValidStep1({
        ...formValidStep1,
        [name]: false,
      });
    } else if (step === 2){
      setFormDataStep3({
        ...formDataStep3,
        [name]: value,
      });
      formValidStep3[name] && setFormValidStep3({
        ...formValidStep3,
        [name]: false,
      });
    }
  

  };

  const handlenTerminosCondicionesClick = () => {
    navigate('/terminos-y-condiciones', { state: {
        page: '/socios', 
        persist: {
          formDataStep1, 
          formDataStep3,
          opcionesSeleccionadas,
          opcionesSeleccionadasRubros,
          opcionEstrategica,
          opcionTradicional,
          editorHtml,
          selectedFiles,
          selectedFilesEmpresa
        }
      } })
  }

  const handleSubmit = () => {
    const combinedFormData = {
      ...formDataStep1,
      ...formDataStep3,
      paises: opcionesSeleccionadas,
      paisesRubros: opcionesSeleccionadasRubros,
      suscripcion: opcionEstrategica ? 'SL ESTRATÉGICA' : 'SL TRADICIONAL',
      editor: editorHtml,
      filesIdPersonal: selectedFiles,
      fileInscripcion: selectedFilesEmpresa
    };

    var adjuntos = []
    let htmlContent = `
    <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
      <thead>
          <tr style="background-color: #f9f9f9;" >
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 10px; text-align: left;">Campo</th>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 10px; text-align: left;">Valor</th>
          </tr>
      </thead>
      <tbody>`;

    for (const [campo, valor] of Object.entries(combinedFormData)) {
      //console.log(campo, valor)
      let titulo = campo; 
      const titulosPersonalizados = {
        apellido: "Apellidos",
        contraseña: "Contraseña",
        correo: "Correo electrónico",
        empCalle: "Calle",
        empDepartamento: "Departamento",
        empEstado: "Estado/Provincia/Región",
        empHAtencion: "Hora de Atención",
        empIdTributo: "Identificación Tributaria de la Empresa",
        empLocalidad: "Localidad",
        empNombre: "Nombre de la Empresa",
        empNumero: "Número",
        empPais: "País",
        empPiso: "Piso",
        empTelefono: "Teléfono Fijo",
        empWeb: "Sitio web de la empresa",
        idPersonal: "ID Personal",
        nombre: "Nombre",
        paises: "Países y Zonas",
        paisesRubros: "Rubros ",
        suscripcion: "Tipo de suscripción",
        telefono: "Teléfono Móvil",
        usuario: "Nombre de usuario",
        editor: "Misión, Visión, Valores, etc",
        filesIdPersonal: "Imagen de ID personal",
        fileInscripcion: "Archivo de Inscripción"
      };

      if (titulosPersonalizados.hasOwnProperty(campo)) {
        titulo = titulosPersonalizados[campo];
      }

      if (Array.isArray(valor)) {
        const items = valor.map(item => {
          if (campo === "paises" || campo === "paisesRubros" ) {
            return `<tr ><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${item}</td></tr>`;
          } else if (/\.(jpg|jpeg|png|gif|pdf)$/i.test(item?.name)) {
            adjuntos.push({name: item.name, content: item.base64})
            return `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" > Archivo adjunto con el nombre (${item.name})</td></tr>`;
          }

          return `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${item}</td></tr>`;
        }).join('');
    
        htmlContent += `${items}`;
        } else if (campo === 'fileInscripcion') {
          if (/\.(jpg|jpeg|png|gif|pdf)$/i.test(valor.name)) {
            adjuntos.push({name: valor.name, content: valor.base64})
            htmlContent += `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" > Archivo adjunto con el nombre (${valor.name})</td></tr>`;
          }
        } else {
          htmlContent += `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${valor}</td></tr>`;
        }
        
    }

    htmlContent += '</tbody></table>';

    const message = {
      destinations: ["admin@softlandingworld.com"],
      subject: "FORMULARIO PARA ASOCIARSE A SWL",
      sender: "info@softlandingworld.com",
      message: htmlContent,
      attachments: adjuntos
    }

    enviarCorreo(message)
      .then(res => {
        console.log("success", res)
        setModalIsOpen(true)
      })
      .catch(error => console.log("Error", error))
  } 


  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '340px'}}></div>
              <h2 className='title_step fontinter fs_20' >RESPONSABLE PRINCIPAL DE LA EMPRESA</h2>
              <div className="subrayado" style={{width: '340px'}}></div>
            </div>
            
            <div className="row sticky">
              <div className={mobile ? "col-12" : "col-6" }>

                <div className="input">
                  <label className='fontInter text-black fs_16'>Nombre *</label>
                  <input
                    type="text"
                    name="nombre"
                    value={formDataStep1.nombre}
                    className={formValidStep1.nombre && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.nombre && <span className="error-message">Campo nombre obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Apellidos *</label>
                  <input
                    type="text"
                    name="apellido"
                    value={formDataStep1.apellido}
                    className={formValidStep1.apellido && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.apellido && <span className="error-message">Campo apellidos obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Dirección de correo electrónico *</label>
                  <input
                    type="email"
                    name="correo"
                    value={formDataStep1.correo}
                    className={formValidStep1.correo && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.correo && <span className="error-message">Campo correo electrónico obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Teléfono Móvil *</label>
                  <input
                    type="text"
                    name="telefono"
                    placeholder='Ej: +54 9 11 12345678'
                    value={formDataStep1.telefono}
                    className={formValidStep1.telefono && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.telefono && <span className="error-message">Campo teléfono móvil obligatorio</span>}
                  </div>
                </div>

              </div>
              <div className={mobile ? "col-12" : "col-6" }>

                <div className="input">
                  <label className='fontinter text-black fs_16'>ID Personal *</label>
                  <input
                    type="text"
                    name="idPersonal"
                    value={formDataStep1.idPersonal}
                    className={formValidStep1.idPersonal && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.idPersonal && <span className="error-message">Campo ID personal obligatorio</span>}
                  </div>
                </div>

                <div className="file-upload-container">
                  <div className="input">
                  <label className='fontinter text-black fs_16'>Adjuntar imagen de ID personal (frente y dorso si aplica) *</label>
                    <input
                      type="file"
                      ref={fileInputRef}
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <div className="sec_error" style={{marginTop: '-10px', marginBottom: '20px'}} >
                      {selectedFilesValidate && <span className="error-message">Campo Adjuntar imagen de ID personal obligatorio</span>}
                    </div>
                    <button onClick={handleButtonClick}>Subir</button>
                    </div>
                  </div>

                <div className="selected-files">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="file-preview">
                    <img src={ `data:image/${file.name.split('.').pop()};base64,${file.base64}`} width={'100%'} alt={`Preview ${index + 1}`} />
                    <span>{file.name}</span>
                  </div>
                ))}
              </div>
                
              </div>
            </div>
         
          </>
        );
      case 2:
        return (
            <>
             <div className="title_decoration">
              <div className="subrayado" style={{width: '450px'}}></div>
              <h2 className='title_step fontinter fs_20' >DATOS DE LA EMPRESA</h2>
              <div className="subrayado" style={{width: '450px'}}></div>
            </div>
            
            <div className="row sticky">
              <div className={mobile ? "col-12" : "col-6" }>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Nombre de la Empresa *</label>
                  <input
                    type="text"
                    name="empNombre"
                    value={formDataStep3.empNombre}
                    className={formValidStep3.empNombre && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep3.empNombre && <span className="error-message">Campo Nombre de la Empresa obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Teléfono Fijo</label>
                  <input
                    type="text"
                    name="empTelefono"
                    placeholder='Ej: 0054 11 12345678'
                    value={formDataStep3.empTelefono}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                  </div>
                </div>

                <div className="input">
                  <label className='fontinter text-black fs_16'>Sitio web de la empresa</label>
                  <input
                    type="text"
                    name="empWeb"
                    placeholder='http://www.sitioweb.com/'
                    value={formDataStep3.empWeb}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                  </div>
                </div>

              </div>
              <div className={mobile ? "col-12" : "col-6" }>
                <div className="input">
                  <label className='fontinter text-black fs_16'>Identificación Tributaria de la Empresa *</label>
                  <input
                    type="text"
                    name="empIdTributo"
                    value={formDataStep3.empIdTributo}
                    className={formValidStep3.empIdTributo && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep3.empIdTributo && <span className="error-message">Campo Identificación obligatorio</span>}
                  </div>
                </div>
                <div className="file-upload-container">
                  <div className="input">
                  <label className='fontinter text-black fs_16'>Adjuntar archivo de Inscripción *</label>
                    <input
                      type="file"
                      ref={fileInputRef2}
                      accept=".jpg, .jpeg, .png, application/pdf"
                      onChange={handleFileChangeEmpresa}
                      style={{ display: 'none' }}
                    />
                    <div className="sec_error" style={{marginTop: '-10px', marginBottom: '20px'}} >
                      {selectedFilesEmpresaValidate && <span className="error-message">Campo archivo de Inscripción obligatorio</span>}
                    </div>
                    <button onClick={handleButtonClick2}>Subir</button>
                    </div>
                  </div>

              </div>
            </div>

            <div className="row sticky">
              <div className="row">
                <div className="col-12">
                  <div className="input">
                    <label className='fontinter text-black fs_16'>Si lo desea, agregue información que considere importante (Misión, Visión, Valores, etc).</label>
                    <div className="content_editor">
                      <ReactQuill
                        theme="snow"
                        value={editorHtml}
                        onChange={handleChangeEditor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row sticky">
              <div className="row">
                <div className={mobile ? "col-12" : "col-6" }>
                  <div className="input">
                    <label className='fontinter text-black fs_16'>Calle *</label>
                    <input
                      type="text"
                      name="empCalle"
                      value={formDataStep3.empCalle}
                      className={formValidStep3.empCalle && 'input_error'}
                      onChange={handleChange}
                    />
                    <div className="sec_error">
                      {formValidStep3.empCalle && <span className="error-message">Campo contraseña obligatorio</span>}
                    </div>
                  </div>
                  <div className="input">
                    <label className='fontinter text-black fs_16'>Piso</label>
                    <input
                      type="text"
                      name="empPiso"
                      value={formDataStep3.empPiso}
                      onChange={handleChange}
                    />
                    <div className="sec_error">
                    </div>
                  </div>
                  <div className="input">
                    <label className='fontinter text-black fs_16'>Localidad *</label>
                    <input
                      type="text"
                      name="empLocalidad"
                      value={formDataStep3.empLocalidad}
                      className={formValidStep3.empLocalidad && 'input_error'}
                      onChange={handleChange}
                    />
                    <div className="sec_error">
                      {formValidStep3.empLocalidad && <span className="error-message">Campo Localidad obligatorio</span>}
                    </div>
                  </div>
                  <div className="input">
                    <label className='fontinter text-black fs_16'>País *</label>
                    <input
                      type="text"
                      name="empPais"
                      value={formDataStep3.empPais}
                      className={formValidStep3.empPais && 'input_error'}
                      onChange={handleChange}
                    />
                    <div className="sec_error">
                      {formValidStep3.empPais && <span className="error-message">Campo País obligatorio</span>}
                    </div>
                  </div>
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                    <div className="input">
                      <label className='fontinter text-black fs_16'>Número *</label>
                      <input
                        type="number"
                        name="empNumero"
                        value={formDataStep3.empNumero}
                        className={formValidStep3.empNumero && 'input_error'}
                        onChange={handleChange}
                      />
                      <div className="sec_error">
                        {formValidStep3.empNumero && <span className="error-message">Campo Número obligatorio</span>}
                      </div>
                    </div>
                    <div className="input">
                      <label className='fontinter text-black fs_16'>Departamento</label>
                      <input
                        type="text"
                        name="empDepartamento"
                        value={formDataStep3.empDepartamento}
                        onChange={handleChange}
                      />
                      <div className="sec_error">
                      </div>
                    </div>
                    <div className="input">
                      <label className='fontinter text-black fs_16'>Estado/Provincia/Región *</label>
                      <input
                        type="text"
                        name="empEstado"
                        value={formDataStep3.empEstado}
                        className={formValidStep3.empEstado && 'input_error'}
                        onChange={handleChange}
                      />
                      <div className="sec_error">
                        {formValidStep3.empEstado && <span className="error-message">Campo Estado/Provincia/Región obligatorio</span>}
                      </div>
                    </div>
                    <div className="input">
                      <label className='fontinter text-black fs_16'>Hora de Atención *</label>
                      <input
                        type="text"
                        name="empHAtencion"
                        placeholder='Ej: Lunes a Viernes de 9 a 18 hs'
                        value={formDataStep3.empHAtencion}
                        className={formValidStep3.empHAtencion && 'input_error'}
                        onChange={handleChange}
                      />
                      <div className="sec_error">
                        {formValidStep3.empHAtencion && <span className="error-message">Campo Hora de Atención obligatorio</span>}
                      </div>
                    </div>
                </div>
              </div>
            </div>

          </>
        )
        case 3:
          return (
          <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '300px'}}></div>
              <h2 className='title_step fontinter fs_20' >PAÍSES Y ZONAS DONDE DESEA BRINDAR SUS SERVICIOS</h2>
              <div className="subrayado" style={{width: '300px'}}></div>
            </div>

            <span className='fontInter fs_20 mb'>* Puede seleccionar más de 1 país y más de 1 región por país</span><br /><br />
            <span className='fontInter fs_20'>* Se requiere seleccionar al menos 1 país y 1 región si tiene</span>
            
            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '500px'}}></div>
              <h2 className='title_step fontinter fs_20' >América del Sur</h2>
              <div className="subrayado gris" style={{width: '500px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontinterbold fs_18' >Países de América del Sur</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(0, 5).map((opcion, index) => (
                    <div className='check' key={index}>
                      <input
                        type="checkbox"
                        id={`opcion-${index}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(5, 9).map((opcion, index) => (
                      <div className='check' key={index +5}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +5}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +5}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '500px'}}></div>
              <h2 className='title_step fontinter fs_20' >Centroamérica</h2>
              <div className="subrayado gris" style={{width: '500px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontinterbold fs_18' >Países de Centroamérica</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(9, 11).map((opcion, index) => (
                    <div className='check' key={index +9}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +9}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +9}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(11, 13).map((opcion, index) => (
                      <div className='check' key={index +11}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +11}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +11}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '480px'}}></div>
              <h2 className='title_step fontinter fs_20' >América del Norte</h2>
              <div className="subrayado gris" style={{width: '480px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontinterbold fs_18' >Países de América del Norte</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(13, 15).map((opcion, index) => (
                    <div className='check' key={index +13}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +13}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +13}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(15, 16).map((opcion, index) => (
                      <div className='check' key={index +15}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +15}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +15}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '540px'}}></div>
              <h2 className='title_step fontinter fs_20' >Europa</h2>
              <div className="subrayado gris" style={{width: '540px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontinterbold fs_18' >Países de Europa</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(16, 19).map((opcion, index) => (
                    <div className='check' key={index +16}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +16}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +16}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(19, 21).map((opcion, index) => (
                      <div className='check' key={index +19}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +19}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +19}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

          </>
        );
      case 4: 
      return (
        <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '530px'}}></div>
              <h2 className='title_step fontinter fs_20' >RUBROS</h2>
              <div className="subrayado" style={{width: '530px'}}></div>
            </div>

            <div className="row sticky">
                <span className='title_bold fontinterbold fs_18' >Rubros *</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {rubros.slice(0, 8).map((opcion, index) => (
                    <div className='check' key={index}>
                      <input
                        type="checkbox"
                        id={`opcion-${index}`}
                        checked={opcionesSeleccionadasRubros.includes(opcion)}
                        onChange={() => handleCheckboxChangeRubros(opcion)}
                      />
                      <label htmlFor={`opcion-${index}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {rubros.slice(8, 17).map((opcion, index) => (
                      <div className='check' key={index +7}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +7}`}
                          checked={opcionesSeleccionadasRubros.includes(opcion)}
                          onChange={() => handleCheckboxChangeRubros(opcion)}
                        />
                        <label htmlFor={`opcion-${index +7}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

          </>
      ); 
      case 5 :
      return (
        <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '400px'}}></div>
              <h2 className='title_step fontinter fs_20' >TIPO DE SUSCRIPCIÓN QUE DESEA</h2>
              <div className="subrayado" style={{width: '400px'}}></div>
            </div>

            <div className="row sticky">
                <div className={mobile ? "col-12" : "col-6" }>
                    <div className='check' key={'t1'}>
                      <input
                        type="checkbox"
                        id="tradicional"
                        checked={opcionTradicional}
                        onChange={() => {
                          setOpcionEstrategica(false);
                          setOpcionTradicional(true)
                        }}
                      />
                      <label htmlFor="tradicional">{'SL TRADICIONAL'}</label>
                    </div>
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                <div className={mobile ? "col-12" : "col-6" }>
                    <div className='check' key={'e2'}>
                      <input
                        type="checkbox"
                        id="estrategica"
                        checked={opcionEstrategica}
                        onChange={() => {
                          setOpcionEstrategica(true);
                          setOpcionTradicional(false)
                        }}
                      />
                      <label htmlFor="estrategica">{'SL ESTRATÉGICA'}</label>
                    </div>
                </div>
                </div>
            </div>

          </>
      )
      default:
        return null;
    }
  };

  return (
    <>
    <div className={`container socios ${mobile && "movile"}`} style={{ marginBottom: '120px' }}>
      <div className="display">
        <h2 className="fontInter fs_24">FORMULARIO PARA ASOCIARSE A SWL</h2>
        <span className='fontInter fs_20'>Aclaración IMPORTANTE: todos los items con * son obligatorios</span>
      </div>
      <div className='step'>
        {renderStep()}
          {step > 1 && <button className='chevronLeft' onClick={handlePrev}> <img src={ChevronLeft} alt="" /> </button>}
          {step < 6 ? (
            <button className='chevronRight' onClick={handleNext}> <img src={ChevronRight} alt="" /> </button>
          ) : (
            !isAceptar ? (
              <div className="finish">
                <span className="fnSTitle">Entiendo que al postularme enviando este formulario he leído y aceptado los</span><br />
                <a href='#' onClick={() => handlenTerminosCondicionesClick()} className='fnTitle'> <span className='fontInter fs_24' >TÉRMINOS Y CONDICIONES</span></a>
              </div>
            ) : (
              <div className="finish">
                 {/* <ReCAPTCHA
                  sitekey="6Le_7hMpAAAAANEr9E4n1iv0SeuqGNUCPhxu2PxA"
                  onChange={handleCheckboxChangeReCaptcha}
                />  */}
                <button onClick={() => handleSubmit()} className='envairForm' >
                  <span className='fontInter text-white fs_20'>Enviar</span>
                </button>
              </div>
            )
          )}
      </div>
    </div>

    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setModalIsOpen(false);
        navigate('/home');
      }}
      className="custom-modal"
    >
      <div className="content_modal">
        <div className="body_modal">
          <h2 className='fontInterBold text-white fs_48' >¡Gracias por elegir la red <br /> Soft Landing World!</h2>
          <span className='fontInter text-white fs_24' >El formulario se envió exitosamente.</span>
          <button onClick={() => {
            setModalIsOpen(false);
            navigate('/home');
          }} className='closeModal' >
              <span className='fontInterBold text-black fs_24' >VOLVER A LA HOME</span>
          </button>
        </div>
      </div>
    </ReactModal>
    </>
  )
}

export default Socios