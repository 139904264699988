import React, { useEffect, useRef, useState } from 'react'
import ChevronLeft from '../../assets/img/chevron-left.png'
import ChevronRight from '../../assets/img/chevron-right.png'
import './clientes.css'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import { enviarCorreo } from '../../services/correo';

const opciones = ['Argentina', 'Brasil', 'Colombia', 'Paraguay', 'Uruguay', 'Bolivia', 'Chile', 'Ecuador', 'Perú', 
'Costa Rica', 'Honduras', 'Guatemala', 'Panamá', 'Canadá', 'Máxico', 'Estados Unidos', 
'Bulgaria', 'Italia', 'Gran Bretaña', 'España', 'Portugal'];

const rubros = ['Legal / Notarias', 'Estudio de Mercado', 'Representaciones Comerciales - ventas', 'Gestoría', 'Seguros', 
'Marketing - Planificación - Diseño y Comunicación', 'Compliance Risk', 'Contable - Asesoría Financiera', 
'Comex - Despachante de Aduana', 'Logística Internacional', 'Real Estate / Inmobiliaria', 'Recursos Humanos', 'Coaching', 'Sistema de Gestión y de IT', 
'Consultoría en Idiomas - Traductorado Público', 'Cobranzas corporativas']

const Clientes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const mobile = window.matchMedia('(max-width: 540px)').matches

  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);
  const [opcionesSeleccionadasRubros, setOpcionesSeleccionadasRubros] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [step, setStep] = useState(1)

  const [formDataStep1, setFormDataStep1] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    telefono: '',
    consulta: ''
  })

  const [formValidStep1, setFormValidStep1] = useState({
    nombre: false,
    apellido: false,
    correo: false,
    telefono: false,
  });

    useEffect(() => {
        if ( location?.state) {
          setFormDataStep1( location.state.persist.formDataStep1 )
          setOpcionesSeleccionadas( location.state.persist.opcionesSeleccionadas )
          setOpcionesSeleccionadasRubros( location.state.persist.opcionesSeleccionadasRubros )
          setStep(7)
        }
        
    }, [location.state])

  const handleNext = () => {
    var allFieldsValid = true

    if (step === 1) {

      Object.entries(formDataStep1).map((data) => {
        if (data[1] === '' && formValidStep1.hasOwnProperty(data[0])) {
          allFieldsValid = false
          setFormValidStep1((prevFormValidStep) => ({
            ...prevFormValidStep,
            [data[0]]: true,
          }))
        }
        return null;
      })
  
    }else if (step === 2 && opcionesSeleccionadas.length === 0){
      allFieldsValid = false
    }

    if (allFieldsValid) {
      setStep(step + 1);
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleCheckboxChange = (opcion) => {
    if (opcionesSeleccionadas.includes(opcion)) {
      setOpcionesSeleccionadas(opcionesSeleccionadas.filter((item) => item !== opcion));
    } else {
      setOpcionesSeleccionadas([...opcionesSeleccionadas, opcion]);
    }
  };

  const handleCheckboxChangeRubros = (opcion) => {
    if (opcionesSeleccionadasRubros.includes(opcion)) {
      setOpcionesSeleccionadasRubros(opcionesSeleccionadasRubros.filter((item) => item !== opcion));
    } else {
      setOpcionesSeleccionadasRubros([...opcionesSeleccionadasRubros, opcion]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormDataStep1({
      ...formDataStep1,
      [name]: value,
    });
    formValidStep1[name] && setFormValidStep1({
      ...formValidStep1,
      [name]: false,
    });
  };
  
  const handleSubmit = () => {
    const combinedFormData = {
      ...formDataStep1,
      paises: opcionesSeleccionadas,
      paisesRubros: opcionesSeleccionadasRubros,
    };

    var adjuntos = []
    let htmlContent = `
    <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
      <thead>
          <tr style="background-color: #f9f9f9;" >
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 10px; text-align: left;">Campo</th>
              <th style="background-color: #f2f2f2; border: 1px solid #dddddd; padding: 10px; text-align: left;">Valor</th>
          </tr>
      </thead>
      <tbody>`;

    for (const [campo, valor] of Object.entries(combinedFormData)) {
      let titulo = campo; 
      const titulosPersonalizados = {
        apellido: "Apellidos",
        contraseña: "Contraseña",
        correo: "Correo electrónico",
        empCalle: "Calle",
        empDepartamento: "Departamento",
        empEstado: "Estado/Provincia/Región",
        empHAtencion: "Hora de Atención",
        empIdTributo: "Identificación Tributaria de la Empresa",
        empLocalidad: "Localidad",
        empNombre: "Nombre de la Empresa",
        empNumero: "Número",
        empPais: "País",
        empPiso: "Piso",
        empTelefono: "Teléfono Fijo",
        empWeb: "Sitio web de la empresa",
        nombre: "Nombre",
        paises: "Países y Zonas",
        paisesRubros: "Rubros ",
        suscripcion: "Tipo de suscripción",
        telefono: "Teléfono Móvil",
        usuario: "Nombre de usuario",
        editor: "Misión, Visión, Valores, etc",
        fileInscripcion: "Archivo de Inscripción"
      };

      if (titulosPersonalizados.hasOwnProperty(campo)) {
        titulo = titulosPersonalizados[campo];
      }

      if (Array.isArray(valor)) {
        const items = valor.map(item => {
          if (campo === "paises" || campo === "paisesRubros" ) {
            return `<tr ><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${item}</td></tr>`;
          }

          return `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${item}</td></tr>`;
        }).join('');
    
        htmlContent += `<ul>${items}</ul>`;
      } else {
        htmlContent += `<tr><td style="border: 1px solid #dddddd; padding: 10px;" >${titulo}</td> <td style="border: 1px solid #dddddd; padding: 10px;" >${valor}</td></tr>`;
      }
    }

    htmlContent += '</tbody></table>';

    const message = {
      destinations: ["admin@softlandingworld.com"],
      subject: "FORMULARIO PARA SOLICITAR COTIZACIÓN A SWL",
      sender: "info@softlandingworld.com",
      message: htmlContent,
      attachments: adjuntos
    }

    enviarCorreo(message)
      .then(res => {
        console.log("success", res)
        setModalIsOpen(true)
      })
      .catch(error => console.log("Error", error))
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '340px'}}></div>
              <h2 className='title_step fontInter fs_20' >DATOS DEL INTERESADO</h2>
              <div className="subrayado" style={{width: '340px'}}></div>
            </div>
            
            <div className="row sticky">
              <div className={mobile ? "col-12" : "col-6" }>

                <div className="input">
                  <label className="fontinter text-black fs_16" >Nombre *</label>
                  <input
                    type="text"
                    name="nombre"
                    value={formDataStep1.nombre}
                    className={formValidStep1.nombre && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.nombre && <span className="error-message">Campo nombre obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className="fontinter text-black fs_16" >Apellidos *</label>
                  <input
                    type="text"
                    name="apellido"
                    value={formDataStep1.apellido}
                    className={formValidStep1.apellido && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.apellido && <span className="error-message">Campo apellidos obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className="fontinter text-black fs_16" >Dirección de correo electrónico *</label>
                  <input
                    type="email"
                    name="correo"
                    value={formDataStep1.correo}
                    className={formValidStep1.correo && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.correo && <span className="error-message">Campo correo electrónico obligatorio</span>}
                  </div>
                </div>

                <div className="input">
                  <label className="fontinter text-black fs_16" >Teléfono Móvil *</label>
                  <input
                    type="text"
                    name="telefono"
                    placeholder='Ej: +54 9 11 12345678'
                    value={formDataStep1.telefono}
                    className={formValidStep1.telefono && 'input_error'}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                    {formValidStep1.telefono && <span className="error-message">Campo teléfono móvil obligatorio</span>}
                  </div>
                </div>

              </div>
              <div className={mobile ? "col-12" : "col-6" }>


              <div className="input">
                  <label className="fontinter text-black fs_16" >Consulta</label>
                  <textarea
                    type="text"
                    name="consulta"
                    value={formDataStep1.consulta}
                    onChange={handleChange}
                  />
                  <div className="sec_error">
                  </div>
                </div>
                
              </div>
            </div>
         
          </>
        );
        case 2:
          return (
          <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '300px'}}></div>
              <h2 className='title_step fontInter fs_20' >PAÍSES DONDE DESEA "ATERRIZAR"</h2>
              <div className="subrayado" style={{width: '300px'}}></div>
            </div>

            <span className='fontInter fs_20 mb'>* Puede seleccionar más de 1 país y más de 1 región por país</span><br /><br />
            <span className='fontInter fs_20'>* Se requiere seleccionar al menos 1 país y 1 región si tiene</span>
            
            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '500px'}}></div>
              <h2 className='title_step fontInter fs_20' >América del Sur</h2>
              <div className="subrayado gris" style={{width: '500px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontInterBold fs_18' >Países de América del Sur</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(0, 5).map((opcion, index) => (
                    <div className='check' key={index}>
                      <input
                        type="checkbox"
                        id={`opcion-${index}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(5, 9).map((opcion, index) => (
                      <div className='check' key={index +5}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +5}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +5}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '500px'}}></div>
              <h2 className='title_step fontInter fs_20' >Centroamérica</h2>
              <div className="subrayado gris" style={{width: '500px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontInterBold fs_18' >Países de Centroamérica</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(9, 11).map((opcion, index) => (
                    <div className='check' key={index +9}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +9}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +9}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(11, 13).map((opcion, index) => (
                      <div className='check' key={index +11}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +11}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +11}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '480px'}}></div>
              <h2 className='title_step fontInter fs_20' >América del Norte</h2>
              <div className="subrayado gris" style={{width: '480px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontInterBold fs_18' >Países de América del Norte</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(13, 15).map((opcion, index) => (
                    <div className='check' key={index +13}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +13}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +13}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(15, 16).map((opcion, index) => (
                      <div className='check' key={index +15}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +15}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +15}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

            <div className="title_decoration mt-5">
              <div className="subrayado gris" style={{width: '540px'}}></div>
              <h2 className='title_step fontInter fs_20' >Europa</h2>
              <div className="subrayado gris" style={{width: '540px'}}></div>
            </div>
            <div className="row sticky">
                <span className='title_bold fontInterBold fs_18' >Países de Europa</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(16, 19).map((opcion, index) => (
                    <div className='check' key={index +16}>
                      <input
                        type="checkbox"
                        id={`opcion-${index +16}`}
                        checked={opcionesSeleccionadas.includes(opcion)}
                        onChange={() => handleCheckboxChange(opcion)}
                      />
                      <label htmlFor={`opcion-${index +16}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {opciones.slice(19, 21).map((opcion, index) => (
                      <div className='check' key={index +19}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +19}`}
                          checked={opcionesSeleccionadas.includes(opcion)}
                          onChange={() => handleCheckboxChange(opcion)}
                        />
                        <label htmlFor={`opcion-${index +19}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

          </>
        );
      case 3: 
      return (
        <>
            <div className="title_decoration">
              <div className="subrayado" style={{width: '530px'}}></div>
              <h2 className='title_step fontInter fs_20' >RUBROS</h2>
              <div className="subrayado" style={{width: '530px'}}></div>
            </div>

            <div className="row sticky">
                <span className='title_bold fontInterBold fs_18' >Rubros</span>
                <div className={mobile ? "col-12" : "col-6" }>
                  {rubros.slice(0, 8).map((opcion, index) => (
                    <div className='check' key={index}>
                      <input
                        type="checkbox"
                        id={`opcion-${index}`}
                        checked={opcionesSeleccionadasRubros.includes(opcion)}
                        onChange={() => handleCheckboxChangeRubros(opcion)}
                      />
                      <label htmlFor={`opcion-${index}`}>{opcion}</label>
                    </div>
                  ))}
                </div>
                <div className={mobile ? "col-12" : "col-6" }>
                  {rubros.slice(8, 17).map((opcion, index) => (
                      <div className='check' key={index +8}>
                        <input
                          type="checkbox"
                          id={`opcion-${index +8}`}
                          checked={opcionesSeleccionadasRubros.includes(opcion)}
                          onChange={() => handleCheckboxChangeRubros(opcion)}
                        />
                        <label htmlFor={`opcion-${index +8}`}>{opcion}</label>
                      </div>
                    ))}
                </div>
            </div>

          </>
      ); 
      default:
        return null;
    }
  };

  return (
    <>
    <div className={`container clientes ${mobile && "movile"}`} style={{ marginBottom: '120px' }}>
      <div className="display">
        <h2 className="fontInter fs_24">FORMULARIO PARA SOLICITAR COTIZACIÓN A SWL</h2>
        <span className='fontInter fs_20'>Aclaración IMPORTANTE: todos los items con * son obligatorios</span>
      </div>
      <div className='step'>
        {renderStep()}
          {step > 1 && <button className='chevronLeft' onClick={handlePrev}> <img src={ChevronLeft} alt="" /> </button>}
          {step < 3 ? (
            <button className='chevronRight' onClick={handleNext}> <img src={ChevronRight} alt="" /> </button>
          ) : (
              <div className="finish">
                <button onClick={() => handleSubmit()} disabled={opcionesSeleccionadasRubros.length === 0 ? true: false} className='envairForm' >
                  <span className='fontInter text-white fs_20'>Enviar</span>
                </button>
              </div>
          )}
      </div>
    </div>

    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setModalIsOpen(false);
        navigate('/home');
      }}
      className="custom-modal"
    >
      <div className="content_modal">
        <div className="body_modal">
          <h2 className='fontInterBold text-white fs_48 mb-4' >¡Gracias por elegir la red <br /> Soft Landing World!</h2>
          <span className='fontInterBold text-white fs_20' >El formulario se envió exitosamente.</span>
          <button onClick={() => {
            setModalIsOpen(false);
            navigate('/home');
          }} className='closeModal fontInterBold text-black fs_24' >
              <span>VOLVER A LA HOME</span>
          </button>
        </div>
      </div>
    </ReactModal>
    </>
  )
}

export default Clientes