import React from "react";
import "./servicios.css";
import { useNavigate } from "react-router-dom";

const Servicios = () => {
  const navigate = useNavigate();
  const mobile = window.matchMedia('(max-width: 540px)').matches

  return (
    <div className="container-banner servicio">
      <div className="container container_banner">
        <div className={`tags ${mobile && "mobil"}`}>
          <div className="tag">
            <div className="item_tag">
              <span className="text-white fs_16" > Legal | Notarial</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Estudio de Mercado</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Contable | Asesoría Financiera</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Representaciones Comerciales | Ventas</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Comex | Despachante de Aduana</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Logística Internacional</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Gestoría | Trámites varios</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Seguros</span>
            </div>
          </div>
          <div className="tag">
            <div className="item_tag">
              <span className="text-white fs_16" >Real Estate | Inmobiliaria</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Recursos Humanos</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Marketing | Planificación | Diseño y Comunicación</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Coaching</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Compliance Risk</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Sistemas de Gestión y de IT</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Consultoría en Idiomas | Traductorado Público</span>
            </div>
            <div className="item_tag">
              <span className="text-white fs_16" >Cobranzas corporativas</span>
            </div>
          </div>
        </div>
      </div>

      <div class="d-grid gap-2 col-8 mx-auto">
        <button onClick={() => navigate('/clientes')} class="btn btn-primary btn-new" type="button">
          <span className="text_button">COTIZAR</span>
        </button>
      </div>
    </div>
  );
};

export default Servicios;
