import React from "react";
import LogoFooter from "../../assets/img/logo-footer.png";
import Sponsor1 from "../../assets/img/sponsor1.png";
import SponsorM1 from "../../assets/img/sponsorM1.png";
import Sponsor2 from "../../assets/img/sponsor2.png";
import SponsorM2 from "../../assets/img/sponsorM2.png";
import Sponsor3 from "../../assets/img/sponsor3.png";
import SponsorM3 from "../../assets/img/sponsorM3.png";
import IconPhone from "../../assets/img/phone.png";
import IconMail from "../../assets/img/mail.png";
import "./footer.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const screen = window.matchMedia('(max-width: 820px)').matches
  const screen1024 = window.matchMedia('(max-width: 1280px)').matches

  return (
    <>
    <div className="sub-footer">
        <div className="sponsors">
          { 
            !screen ? (
              <>
                <img className="Sponsor1" src={Sponsor1} alt="Platinium" />
                <img className="Sponsor2" src={Sponsor2} alt="Gold" />
                <img className="Sponsor3" src={Sponsor3} alt="Silver" />
              </>
            ) : (
              <>
                <img className="sponsor" src={SponsorM1} alt="Platinium" />
                <img className="sponsor" src={SponsorM2} alt="Gold" />
                <img className="sponsor" src={SponsorM3} alt="Silver" />
              </>
            )
          }
          
        </div>
      </div>

      <div className="footer">
        <div className="container"  >
          <footer>
            <div className="row">
              <div className={!screen ? screen1024 ? "col-1" : "col-2" : "col-4"}>
                <img src={LogoFooter} width="100%" alt="Soft Landing World" />
              </div>
              <div className={!screen ? "col-3" : "col-8"}>
                <h5 className="fontInter fs_16" >CONTACTENOS</h5>
                <p className="fontinter fs_16" > 
                  <a href="mailto:admin@softlandingworld.com" style={{color: 'var(--secondary)', textDecoration: 'none'}}> 
                    <img src={IconMail} width="20px" alt="" /> admin@softlandingworld.com 
                  </a>
                </p>
                <p className="fontinter fs_16" style={{color: 'var(--secondary)'}} > 
                  <a href="tel:+5491166804951" style={{color: 'var(--secondary)', textDecoration: 'none'}}> 
                    <img src={IconPhone} width="20px" alt="" /> +54 9 11 66804951
                  </a>
                </p>
              </div>
              {
                !screen && (
                  <>
                  <div className="col-1">
                    <h5 className="fontInter fs_16" >SLW</h5>
                    <p className="fontinter fs_16" onClick={() => navigate('/beneficios')}>Beneficios</p>
                    <p className="fontinter fs_16" onClick={() => navigate('/etapa')}>Etapas</p>
                  </div>
                  <div className={screen1024 ? "col-3" : "col-2"}>
                    <h5 className="fontInter fs_16" >SERVICIOS & COBERTURA</h5>
                    <p className="fontinter fs_16" onClick={() => navigate('/rubros-de-servicios')}>Rubros de Servicios</p>
                    <p className="fontinter fs_16" onClick={() => navigate('/paises-de-cobertura')}>Países de Cobertura</p>
                  </div>
                  <div className="col-2">
                    <h5 className="fontInter fs_16" >FORMULARIOS</h5>
                    <p className="fontinter fs_16" onClick={() => navigate('/clientes')}>Para Clientes</p>
                    <p className="fontinter fs_16" onClick={() => navigate('/socios')}>Para Profesionales</p>
                  </div>
                  <div className="col-2">
                    <h5 className="fontInter fs_16" >SUSCRIPCIONES</h5>
                    <p className="fontinter fs_16" onClick={() => navigate('/suscripciones', { state: { scroll: false } })}>SL Tradicional</p>
                    <p className="fontinter fs_16" onClick={() => navigate('/suscripciones', { state: { scroll: true } })} >SL Estratégico</p>
                  </div>
              </>
                )
              }
              
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Footer;
