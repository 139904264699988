import React from 'react'
import './cardBanner.css'
import { Link } from 'react-router-dom'

const CardBanner = ({title, text, image, link, isSuscribe = false, isRight = false}) => {
  return (
    <div className={isRight ? 'cont_card isRight' : 'cont_card'}>
    <div class="card mb-3" >
            { 
                !isRight && (
                    <div className="cont_img">
                        <img src={image} width="400px" alt="Nuestra Vision" />
                    </div>
                )
            }
            
                <div className={ isRight ? 'card-body card-right' : 'card-body'}>
                    <h5 class="card-title fontInter fs_16">{title}</h5>
                    <p class="card-text">{text}</p>
                    <div className="d-flex" style={{ justifyContent: !isRight ? 'space-between' : 'end' }}>
                        <Link to={link} className='btn_card fs_22'> MÁS... </Link>
                        {isSuscribe && <Link className='suscrip' to={'/suscripciones'}> <h2 className='btn_card fs_22'>QUIERO SUSCRIBIRME</h2></Link>}
                    </div>
                </div>

            { 
                isRight && (
                    <img src={image} width="400px" alt="Nuestra Vision" />
                )
            }
    </div>
    </div>
  )
}

export default CardBanner