import React from 'react'
import './etapas.css'
import ImgNumero1 from '../../../assets/img/numero1.png'
import ImgNumero2 from '../../../assets/img/numero2.png'
import ImgNumero3 from '../../../assets/img/numero3.png'
import Escalera from '../../../assets/img/Escalera.png'
import Etapa from './etapa/Etapa'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const Etapas = () => {
  const [etapaActive, setSetEtapaActive] = useState("etapa_1")
  const navigate = useNavigate();
  const screen = window.matchMedia('(max-width: 820px)').matches

  const handleChangeEtapa = (etapa) => {
    setSetEtapaActive(etapa)
  }

  return (
    <div className="container-banner etapa">
      <div className="fondo">
       <img src={Escalera} alt="" className='escalera' width="100%" />
      </div>
      
      <div className={!screen ? "container container_banner" : "container container_banner mobile"}>
       
       <div className={!screen ? "cont_desk" : "cont_mobile"}>
        <Etapa image={ImgNumero1} title={'ANÁLISIS'} classe="etapa_1" isChildren={etapaActive === "etapa_1" ? true : false} handleEtapa={handleChangeEtapa} subTitle={'Proyectivo'} styleImg={{marginLeft: '12px'}} >
          <p>1. Estudio de mercado</p>
          <p>2. Estudio Tributario</p>
          <p>3. Análisis legal</p>
          <p>4. Marketing, Planificación, Diseño y Comunicación</p>
        </Etapa>
        <Etapa image={ImgNumero2} classe="etapa_2" isChildren={etapaActive === "etapa_2" ? true : false} handleEtapa={handleChangeEtapa}  title={'LANDING'} >
          <p>1. Legal / Notarial</p>
          <p>2. Contable - Asesoría financiera</p>
          <p>3. Gestoría / Tramites varios </p>
          <p>4. Real estate / inmobiliaria</p>
          <p>5. Seguros </p>
          <p>6. Recursos Humanos </p>
          <p>7. Sistemas de Gestión y de IT</p>
          <p>8. Consultoría en idiomas y Traductorado Público</p>
          <p>9. Representaciones comerciales  / ventas</p>
        </Etapa>
        <Etapa image={ImgNumero3} classe="etapa_3" isChildren={etapaActive === "etapa_3" ? true : false} handleEtapa={handleChangeEtapa} title={'PLANIFICACIÓN'}  subTitle={'Estratégica'} >
          <p>1. Comex / Despachante de aduana</p>
          <p>2. Logística internacional</p>
          <p>3. Marketing, Planificación, Diseño y Comunicación</p>
          <p>4. Coaching </p>
          <p>5. Compliance Risk</p>
          <p>6. Cobranzas corporativas</p>
          <p>7. Marketing, Planificación, Diseño y Comunicación</p>
        </Etapa>
      </div>
      </div>

      <div class="d-grid gap-2 col-8 mx-auto">
        <button onClick={() => navigate('/clientes')} class="btn btn-primary btn-new" type="button">
          <span className="text_button fontInterBold">COTIZAR</span>
        </button>
      </div>
    </div>
  )
}

export default Etapas