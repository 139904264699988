import React from 'react'
import './containerBanner.css'
import Button from '../../buttonCircle/Button'
import { useNavigate } from 'react-router-dom';

const ContainerBanner = () => {
  const navigate = useNavigate();
  const mobile = window.matchMedia('(max-width: 540px)').matches

  return (
    <div className="container-banner">
        <div className="container container_banner">
          <div className={`content ${mobile && "movile"}`}>
            <h2 className="title fontInterBold text-white fs_48">
              ¿Estás listo para <br /> expandirte por el mundo?
            </h2>
            <p className="subTitle fontInter text-white fs_24">Vos elegí dónde. <br /> Nosotros nos ocupamos del resto. </p>
            <div class="d-grid gap-2 col-8 mx-auto">
              <button onClick={() => navigate('/clientes')} class="btn btn-primary btn-new" type="button">
                <span className="fontInterBold text-white fs_24">COTIZAR</span>
              </button>
            </div>

            <div className="d-flex justify-content-between" style={{marginTop: '50px'}}>
                <Button style={{cursor: 'none'}} title={'+500'} subTitle={'empresas <br> de éxito'} />
                <Button title={'+110'} subTitle={'consultoras <br> de prestigio'} />
                <Button title={'+16'} subTitle={'rubros <br> de servicios'} />
                <Button title={'+18'} subTitle={'países <br> de cobertura'} />
            </div>
          </div>
        </div>
      </div>
  )
}

export default ContainerBanner