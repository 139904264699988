import axios from 'axios'

export const enviarCorreo = async (message) => {
    try {
        const { data } = await axios.post('https://6yh44yr5wk.execute-api.us-east-1.amazonaws.com/prd/sendmail', message,{
            'Content-Type': 'application/json',                        
        })
        
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}