import React from 'react'
import './maintenance.css'
import Icon from '../../assets/img/icon.png'

const Maintenance = () => {
  return (
    <div className='background-container'>
        <div className="conteiner">
            
            <img src={Icon} alt="" />

            <h2 className='title fontInter fs_48'>
              Estamos mejorando 
              <br />
              nuestra página.
              <br />
              <br />
              ¡Muy pronto novedades!
            </h2>
            <span style={{fontSize: '24px'}} >Te esperamos.</span>
        </div>
    </div>
  )
}

export default Maintenance