import React, { useEffect, useState } from "react";
import Nabvar from "./nabvar/Nabvar";
import Footer from "./footer/Footer";
import Banner from "../components/banner/Banner";
import ContainerBanner from "../components/banner/containerBanner/ContainerBanner";
import ImgBanner from "../assets/img/banner.png";
import ImgBannerMobile from "../assets/img/banner mobile.png";
import ImgBanner2 from "../assets/img/banner 2.png";
import BannerEtapa from "../assets/img/banner etapa.png";
import BannerServicios from "../assets/img/Rubros de Servicios.png";
import BannerMapa from "../assets/img/mapa.png";
import BannerBeneficios from "../assets/img/banner beneficios.png";
import BannerSuscripciones from "../assets/img/suscripciones.png";
import BannerTop from "../assets/img/banner Top.png";
import { useLocation } from "react-router-dom";
import Etapas from "../components/banner/etapas/Etapas";
import Servicios from "../components/banner/servicios/Servicios";
import Mapa from "../components/banner/mapa/Mapa";
import Beneficios from "../components/banner/beneficios/Beneficios";
import Suscripciones from "../components/banner/suscripciones/Suscripciones";

const Layout = ({ children }) => {
  const [image, setImage] = useState(ImgBanner);
  const [height, setHeight] = useState("900px");
  const [chhildrenBanner, setChhildrenBanner] = useState(1);
  const screen = window.matchMedia('(max-width: 820px)').matches
  const { pathname } = useLocation();

  const mobile = window.matchMedia('(max-width: 540px)').matches

  useEffect(() => {
    if (pathname === "/home") {
      setChhildrenBanner(1);
      setHeight("900px");
      setImage(!mobile ? ImgBanner : ImgBannerMobile);
    } else if (pathname === "/que-es-soft-landing") {
      setChhildrenBanner();
      setHeight("106px");
      setImage(ImgBanner2);
    } else if (pathname === "/etapa") {
      setChhildrenBanner(2);
      setHeight("940px");
      setImage(BannerEtapa);
     } else if (pathname === "/rubros-de-servicios") {
      setChhildrenBanner(3);
      setHeight("920px");
      setImage(BannerServicios);
    } else if (pathname === "/paises-de-cobertura") {
      setChhildrenBanner(4);
      setHeight("900px");
      setImage(BannerMapa);
    } else if (pathname === "/beneficios") {
      setChhildrenBanner(5);
      setHeight("900px");
      setImage(BannerBeneficios);
    } else if (pathname === "/suscripciones") {
      setChhildrenBanner(6);
      setHeight("900px");
      setImage(BannerSuscripciones);
    } else if (pathname === "/socios" || pathname === "/clientes") {
      setChhildrenBanner();
      setHeight("106px");
      setImage(BannerTop);
    } else if (pathname === "/terminos-y-condiciones") {
      setChhildrenBanner();
      setHeight("106px");
      setImage(ImgBanner2);
    } 
    scrollToTop()
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={ screen && "mobile_root"}>
      <Nabvar />
      <Banner image={image} height={height} classProps={ pathname === "/paises-de-cobertura" && " mapa_top" || pathname === "/suscripciones" && " position_top" || pathname && pathname.substring(1)}>
        {chhildrenBanner === 1 && <ContainerBanner />}
        {chhildrenBanner === 2 && <Etapas />}
        {chhildrenBanner === 3 && <Servicios />}
        {chhildrenBanner === 4 && <Mapa />}
        {chhildrenBanner === 5 && <Beneficios />}
        {chhildrenBanner === 6 && <Suscripciones />}
      </Banner>
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
