import React, { useEffect, useRef } from "react";
import "./suscripciones.css";
import { useLocation, useNavigate } from "react-router-dom";

const Suscripciones = () => {
    const miDivRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ( location?.state ) {
            const divElement = miDivRef.current;
            scrollToTop()
            if (divElement && location?.state.scroll) {
                divElement.scrollTop = divElement.scrollHeight;
            } else {
                divElement.scrollTop = 0;
            }
        }
        
    }, [location.state])

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
    

  return (
    <div className="container-banner suscripciones">
      <div className="container container_banner">
        <div className="content_sus">
          <div className="sect_card">
            <div className="sect_scroll" ref={miDivRef}>
              <div className="card_sus tradicional">
                <span className="fontInter fs_24">Soft Landing</span>
                <br />
                <span className="fontInterBold text-black fs_24">TRADICIONAL</span>
                <ul>
                  <li>
                    <p>LEGAL</p>
                  </li>
                  <li>
                    <p>CONTABLE | ASESORÍA FINANCIERA</p>
                  </li>
                  <li>
                    <p>COMEX | DESPACHANTE DE ADUANA O AGENTE ADUANAL</p>
                  </li>
                  <li>
                    <p>LOGÍSTICA LOCAL</p>
                  </li>
                  <li>
                    <p>GESTORÍA | TRÁMITES VARIOS</p>
                  </li>
                  <li>
                    <p>SEGUROS</p>
                  </li>
                  <li>
                    <p>COMPLIANCE RISK</p>
                  </li>
                  <li>
                    <p>RRHH (NÓMINA SALARIAL, PEO, OUTSOURCING)</p>
                  </li>
                  <li>
                    <p>REAL ESTATE | INMOBILIARIA</p>
                  </li>
                </ul>
                <div className="subCard">
                  <div className="csSubcard">
                    <span className="fontInter text-white fs_16">LATAM</span>
                    <br />
                    <span className="fontInterBold text-black fs_20">U$S 790</span>
                    <br />
                    <p className="fontInter text-black fs_14">
                      CASH / 3 Y 6 COUOTAS MENSUALES CON INTERÈS
                    </p>
                  </div>
                  <div className="csSubcard">
                    <span className="fontInter text-white fs_16">EUROPA / USA</span>
                    <br />
                    <span className="fontInterBold text-black fs_20">€ 1400 / U$S 1490</span>
                    <br />
                    <p className="fontInter text-black fs_14">
                      CASH / 3 Y 6 COUOTAS MENSUALES CON INTERÈS
                    </p>
                  </div>
                </div>
                <p className="foo_text fontInter text-black-50 fs_14">
                  Suscripción SL <strong>Tradicional</strong> ANUAL. Sólo aplica en el país
                  contratado. <strong>Máximo 5 asociados por mismo Servicio en el país.</strong> <br />
                  Puede contratar varios países
                </p>
              </div>

              <div className="card_sus estrategico">
                <span className="fontInter fs_24">Soft Landing</span>
                <br />
                <span className="fontInterBold text-black fs_24">ESTRATÉGICO</span>
                <ul>
                  <li>
                    <p>ESTUDIO DE MERCADO</p>
                  </li>
                  <li>
                    <p>REPRESENTACIONES COMERCIALES | VENTAS</p>
                  </li>
                  <li>
                    <p>CONSULTORÍA (MARKETING, PLANIFICACIÓN, ETC)</p>
                  </li>
                  <li>
                    <p>COACHING</p>
                  </li>
                  <li>
                    <p>SISTEMAS DE GESTIÓN (ERP, CRM, MRP, ISO’S, BI)</p>
                  </li>
                  <li>
                    <p>COBRANZA CORPORATIVA</p>
                  </li>
                  <li>
                    <p>RECURSOS HUMANOS (RECRUITING, CAPACITACIÓN)</p>
                  </li>
                  <li>
                    <p>LOGÍSTICA INTERNACIONAL</p>
                  </li>
                </ul>
                <div className="subCard">
                  <div className="csSubcard">
                    <span className="fontInter text-white fs_16">LATAM</span>
                    <br />
                    <span className="fontInterBold text-black fs_20">U$S 1090</span>
                    <br />
                    <p className="fontInter text-black fs_14">
                    CASH / 3 Y 6 COUOTAS MENSUALES CON INTERÈS
                    </p>
                  </div>
                  <div className="csSubcard">
                    <span className="fontInter text-white fs_16">EUROPA / USA</span>
                    <br />
                    <span className="fontInterBold text-black fs_20">€ 2810 / U$S 2990</span>
                    <br />
                    <p className="fontInter text-black fs_14">
                    CASH / 3 Y 6 COUOTAS MENSUALES CON INTERÈS
                    </p>
                  </div>
                </div>
                <p className="foo_text fontInter text-black-50 fs_14">
                Suscripción SL <strong>Estratégico</strong> ANUAL. Aplica a todos los países de la red.
                <strong>Màximo 5 asociados por mismo Servicio en la totalidad de la red.</strong>
                </p>
              </div>
            </div>

            <button onClick={() => navigate('/socios')} className="btn btn-primary btn-new" type="button">
              <span className="text_button">QUIERO SUSCRIBIRME</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suscripciones;
