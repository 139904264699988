import React, { useEffect, useState } from 'react'
import CardBanner from '../../components/cardBanner/CardBanner'
import NuestraVision from '../../assets/img/Nuestra Vision.png'
import NuestraVisionMobile from '../../assets/img/Nuestra Vision mobile.png'
import NuestraComunidad from '../../assets/img/Nuestra Comunidad.png'
import NuestraComunidadMobile from '../../assets/img/Nuestra Comunidad mobile.png'
import NuestrosServicion from '../../assets/img/Nuestros Servicion.png'
import NuestrosServicionMobile from '../../assets/img/Nuestros Servicion mobile.png'
import NuestroCompromiso from '../../assets/img/Nuestro Compromiso.png'
import NuestroCompromisoMobile from '../../assets/img/Nuestro Compromiso mobile.png'
import './home.css'
import ReactModal from 'react-modal'

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const mobile = window.matchMedia('(max-width: 540px)').matches

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setModalIsOpen(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);
  return (
    <>
      <div className="container">
          <h1 className={`${mobile && "title_home"}`} style={{color: 'var(--primary)'}}>SOFT LANDING WORLD</h1>
          <h4 className={`${mobile && "subTitle_home"}`}>¿QUIÉNES SOMOS?</h4>

        <div className={`conteiner_card ${mobile && "movil"}`}>
            <CardBanner 
              title={'NUESTRA COMUNIDAD'} 
              image={!mobile ? NuestraComunidad : NuestraComunidadMobile}
              link={'/beneficios'}
              text={'Somos un grupo de Consultoras y Profesionales independientes de todo el mundo, expertos en rubros esenciales y colaborativos que conforman el Soft Landing y que garantizan un servicio integral y seguro.'} 
              isSuscribe={true} 
            />
            <CardBanner 
              title={'NUESTROS SERVICIOS'} 
              image={!mobile ? NuestrosServicion : NuestrosServicionMobile}
              link={'/rubros-de-servicios'}
              isRight={true}
              text={'Contamos con las soluciones adecuadas para su negocio, ya sea desde conceptualizar la idea de su marca, cimentar su proyecto, ponerlo en marcha, consolidarlo o “aterrizarlo” en otro país, gracias a los servicios de nuestros profesionales.'}  
            />
            <CardBanner 
              title={'NUESTRA VISIÓN'} 
              link={'/etapa'}
              image={!mobile ? NuestraVision : NuestraVisionMobile}
              text={'La Red SLW se constituirá en líder del soft landing no sólo por la capacidad de concentrar los mejores expertos en la materia, sino porque además coordinará eventos internacionales para fomentar el comercio exterior.'}  
            />
            <CardBanner 
              title={'NUESTRO COMPROMISO'} 
              image={!mobile ? NuestroCompromiso : NuestroCompromisoMobile}
              link={'/beneficios'}
              isRight={true}
              text={'Coordinar de forma personalizada y segura el aterrizaje de su empresa en otro país y hacer crecer su negocio.'}  
            />
          </div>

      </div>
      <ReactModal
      isOpen={modalIsOpen}
      className="disclemer"
      style={{
        overlay: {
          alignItems: 'start'
        },
        content: {
          marginTop: '95px'
        },
      }}
    >
      <div className="content_modal">
        <div className="body_modal">
          <h2 className='fontInterBold text-white fs_16' >IMPORTANTE:</h2>
          <p className='modal_parrafo fontInter fs_14' >Somos la única <strong>Comunidad de Profesionales</strong> que mantenemos nuestra “<strong>independiencia</strong>”, pero trabajamos mancomunados para ofrecer servicios de soft landing <br />
(desembarco suave).
<br />
<br />
*Los miembros de la SLW desarrollamos nuestros servicios con una visión homogénea y gran nivel de profesionalismo. Cada Asociado es responsable por los asesoramientos y/o negocios y/o servicios que brinda. Al contratar con un profesional de SLW se está aceptando esta condición, entendiendo que Soft Landing World SL (administradora de la red) no responde por los servicios brindados por sus miembros.*
<br />
<br />
Somos profesionales independientes que nos unimos para brindarte todas las herramientas que necesitan las empresas para internacionalizar sus productos o servicios..</p>
          <button onClick={() => {localStorage.setItem('hasVisited', false); setModalIsOpen(false);}} className='closeModal' >
              <span className='fontInterBold text-black fs_24' >OK</span>
          </button>
        </div>
      </div>
    </ReactModal>
    </>
  )
}

export default Home